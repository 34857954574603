<template>
	<div class="selection tire-finder">
		<div class="type-selection" v-if="showToggle">
			<button
				v-for="category in tire_category.options"
				:class="['btn', 'text-white', tire_category.value === category.value ? 'btn-default' : 'btn-primary']"
				@click="setCategory(category.value)"
			>
				{{ category.display_value }}
			</button>
		</div>
		<div class="parameters" :class="{ 'show-images': !showImages, 'tire-margin-top': !showToggle }">
			<div
				class="parameter one-third"
				v-for="(facet, index) in facets"
			>
				<div class="dropdown-container">
					<i
						class="fa fa-check status-check"
						v-if="!!facet.value"
					/>
					<i
						v-else-if="loading"
						class="fa fa-spinner fa-spin"
					/>
					<span
						v-else
						:class="{
							'disabled-facet': !facet.options.length,
						}"
					>
						{{ index + 1 }} |
					</span>
					<select
						v-model="facet.value"
						:disabled="!facet.options.length"
						@change="index < facets.length - 1 ? updateFacet(facet.name) : null"
					>
						<option
							disabled
							:value="null"
						>
							{{ facet.label[tire_category.value] }}
						</option>
						<option v-for="option in facet.options">
							{{ option }}
						</option>
					</select>
				</div>
			</div>
			<div class="submit-button-container">
				<TooltipWrapper
					:show-tooltip="!readyToSubmit"
					@toggle:tooltip="toggleTooltip"
					trigger="hover"
				>
					<button
						class="btn btn-default text-white submit-tire-button"
						@click="submit"
						:disabled="!readyToSubmit"
						data-toggle="tooltip"
						data-placement="bottom"
						data-container="body"
						data-trigger="manual"
						title="Please select all three options to find tires."
						ref="submitTireButton"
					>
						Find Tires
					</button>
				</TooltipWrapper>
			</div>
		</div>
		<div
			v-show="!!errorMessage"
			class="error-message-container"
		>
			<ErrorMessage @reset:error-message="errorMessage = ''">
				{{ errorMessage }}
			</ErrorMessage>
		</div>
		<div v-if="showImages">
            <img
			:src="imageData.src"
			alt="Tire Finder"
			class="desktop-diagram img-responsive"
		/>
            <img
                v-if="isLandingPage"
                :src="imageData.mobileSrc"
                alt="Tire Finder"
                class="mobile-diagram img-responsive"
            />
        </div>
	</div>
</template>

<script>
import { get } from 'lodash';
import Tracking from '@/mixins/event-tracking';

export default {
	mixins: [Tracking],
	components: {
		TooltipWrapper: () => import('@/components/widgets/Tooltip.vue'),
		ErrorMessage: () => import('@/components/fitment-bar/ErrorMessage.vue'),
	},
	props: {
		isLandingPage: {
			type: Boolean,
			default: false,
		},
		defaultCategory: {
			type: String,
			default: 'Motorcycle',
		},
        showImages: {
            type: Boolean,
            default: true,
        },
        showToggle: {
            type: Boolean,
            default: true,
        },
		brandFilter: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			tire_width: {
				value: null,
				options: [],
				name: 'tire_width',
				label: {
					Motorcycle: 'Width',
					ATV: 'Height',
				},
			},
			tire_ratio: {
				value: null,
				options: [],
				name: 'tire_ratio',
				label: {
					Motorcycle: 'Aspect Ratio',
					ATV: 'Width',
				},
			},
			tire_rim_size: {
				value: null,
				options: [],
				name: 'tire_rim_size',
				label: {
					Motorcycle: 'Rim Size',
					ATV: 'Rim Size',
				},
			},
			tire_category: {
				value: this.defaultCategory,
				options: [],
			},
			results: {},
			loading: false,
			errorMessage: '',
		};
	},
	methods: {
		toggleTooltip() {
			$(this.$refs.submitTireButton).tooltip('toggle');
		},
		setCategory(category) {
			this.tire_category.value = category;
			this.tire_width.value = null;
			this.tire_ratio.value = null;
			this.tire_rim_size.value = null;
			this.tire_width.options = [];
			this.tire_ratio.options = [];
			this.tire_rim_size.options = [];
			this.getTireOptions(category, 'tire_width');
		},
		updateFacet(name) {
			let values = ['tire_width', 'tire_ratio', 'tire_rim_size'];
			let index = values.indexOf(name);
			for (let i = index + 1; i < values.length; i++) {
				this[values[i]].value = null;
				this[values[i]].options = [];
			}
			this.getTireOptions(null, values[index + 1]);
		},
		submit() {
			let finderString = window.location.origin + '/csp/tire-finder';
			if (this.brandFilter) {
				finderString = window.location.href;
			}
			let finderUrl = new URL(finderString);
			let filters = [
				{
					name: 'tire_width',
					values: [this.tire_width.value],
				},
				{
					name: 'tire_ratio',
					values: [this.tire_ratio.value],
				},
				{
					name: 'tire_rim_size',
					values: [this.tire_rim_size.value],
				},
				{
					name: 'tire_category',
					values: [this.tire_category.value],
				},
			].filter((filter) => filter.values[0] !== null);
			finderUrl.searchParams.set('filters', JSON.stringify(filters));
			finderUrl.searchParams.delete('use_fitment');
			this.trackEvent(
				'tireFinderSubmit',
				{
					eventCategory: 'Tire Finder',
					eventLabel: 'Tire Finder Submit',
                    tireSize: `${this.tire_width.value}/${this.tire_ratio.value}/${this.tire_rim_size.value}`,
                    tireCategory: this.tire_category.value,
				},
				false
			);
			window.location = finderUrl.href;
		},
		getTireOptions(categorySelection = null, propertyToUpdate = null) {
			this.loading = true;
			this.errorMessage = '';
			let finderString = window.location.origin + '/tire-finder/api';
			let finderUrl = new URL(finderString);
			let filters = [
				{
					name: 'tire_width',
					values: [this.tire_width.value],
				},
				{
					name: 'tire_ratio',
					values: [this.tire_ratio.value],
				},
				{
					name: 'tire_rim_size',
					values: [this.tire_rim_size.value],
				},
			];

			if (categorySelection) {
				filters.push({
					name: 'tire_category',
					values: [categorySelection],
				});
			}

			if (this.brandFilter) {
				filters.push({
					name: 'manufacturer',
					values: this.brandFilter.split(','),
				});
			}

			filters = filters.filter((filter) => filter.values[0] !== null);
			finderUrl.searchParams.set('filters', JSON.stringify(filters));
			return fetch(finderUrl.href)
				.then((response) => response.json())
				.then((response) => {
					this.results = response.data;
					if (propertyToUpdate) {
						this[propertyToUpdate].options = this.formatOptions(this.results.filters, propertyToUpdate);
					}
				})
				.catch(() => {
					this.errorMessage =
						'An error occurred while fetching tire options. Please reload the page to try again.';
				})
				.finally(() => {
					this.loading = false;
				});
		},
		formatOptions(set, name) {
			return set
				.find((filter) => filter.name === name)
				.options.map((option) => option.value)
				.sort((a, b) => a - b);
		},
	},
	computed: {
		imageData() {
			const images = {
				Motorcycle: {
					src: 'https://cdn.partzilla.com/cdn-cgi/image/quality=90,format=auto/MTE/ufs/7/MTc2MjY3NTQ-7912a1da.png',
					mobileSrc:
						'https://cdn.partzilla.com/cdn-cgi/image/quality=90,format=auto/MTE/ufs/0/MTc2MjY3NTU-0293f433.png',
				},
				ATV: {
					src: 'https://cdn.partzilla.com/cdn-cgi/image/quality=90,format=auto/MTE/ufs/c/MTc2MjQxODg-cde6d1b8.png',
					mobileSrc:
						'https://cdn.partzilla.com/cdn-cgi/image/quality=90,format=auto/MTE/ufs/6/MTc2MjQxODk-6a0adfa0.png',
				},
			};
			return images[this.tire_category.value];
		},
		facets() {
			return [this.tire_width, this.tire_ratio, this.tire_rim_size];
		},
		readyToSubmit() {
			return this.tire_width.value && this.tire_ratio.value && this.tire_rim_size.value;
		},
	},
	async mounted() {
		console.log('firing mounted')
		$('[data-toggle="tooltip"]').tooltip();
		const urlParams = new URLSearchParams(window.location.search);
		let urlFilters = urlParams.get('filters');
		let selected = null;
		if (urlFilters) {
			urlFilters = JSON.parse(urlFilters);
			const category = urlFilters.find((filter) => filter.name === 'tire_category');
			if (category) {
				selected = category.values[0];
				this.tire_category.value = selected;
			}
		}

		await this.getTireOptions(selected ?? this.defaultCategory, 'tire_width');
		const tireCategoryOptions = get(this.results, 'filters', []).find((filter) => filter.name === 'tire_category');
		this.tire_category.options = tireCategoryOptions ? tireCategoryOptions.options : [];
		let selection = this.tire_category.options.find((option) => option.selected);
		if (this.tire_category.options.length === 1 && !selection) {
			this.tire_category.value = this.tire_category.options[0].value;
			await this.getTireOptions(this.tire_category.value, 'tire_width');
			const tireCategoryOptions = get(this.results, 'filters', []).find((filter) => filter.name === 'tire_category');
			this.tire_category.options = tireCategoryOptions ? tireCategoryOptions.options : [];
		} else {
			if (selection) {
				this.tire_category.value = selection.value;
			}
		}
	},
};
</script>

<style lang="scss" scoped>
.tire-finder {
	border: 1px solid #e3e3e3;
	margin: 15px 0;
	@media screen and (max-width: 768px) {
		padding: 1rem 1rem 0 1rem;
	}
	.type-selection {
		margin: 2rem;
	}
	.btn:focus {
		outline: none;
	}
	.submit-button-container {
		flex-basis: 10%;
	}
	.error-message-container {
		margin: 2rem 4rem 2rem 2rem;
		@media screen and (max-width: 768px) {
			margin: 2rem;
		}
	}
	.parameters {
		align-items: center;
        justify-content: space-around;
		display: flex;
		gap: 0 10px;
		margin: 0 20px;

        &.show-images {
            margin-bottom: 2rem;
			justify-content: space-between;
        }

		&.tire-margin-top {
			margin-top: 2rem;
		}

		.parameter {
			display: flex;
			flex-basis: 28%;
			flex-direction: column;
			position: relative;

			label {
				align-items: center;
				color: #666;
				display: flex;
				font-size: 1.5rem;
				gap: 0.5rem;
				justify-content: flex-start;
				margin-bottom: 0;
			}

			.dropdown-container {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 5px;
				padding-left: 10px;
				border: 1px solid #222;
				border: 1px solid color(display-p3 0.133 0.133 0.133);
				background-color: #fff;
				.status-check {
					color: #009a00;
				}
				span {
					flex: 1 0 auto;
					opacity: 1;
					&.disabled-facet {
						opacity: 0.5;
					}
				}
				select {
					align-items: center;
					color: #222;
					color: color(display-p3 0.133 0.133 0.133);
					border: none;
					display: flex;
					flex: 1 1 auto;
					justify-content: center;
					max-width: 90%;
					width: 90%;
					padding: 10px 15px 10px 0;
					appearance: none;
					/* Override safari default select styles, add back caret with bg image */
					background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23000'><polygon points='0,0 100,0 50,50'/></svg>")
						no-repeat;
					background-size: 10px;
					background-position: calc(100% - 12px) 20px;
					background-repeat: no-repeat;
					&:focus-visible {
						outline: none;
					}

					&:disabled {
						opacity: 0.5;
					}
				}
			}
		}

		@media screen and (max-width: 992px) {
			flex-wrap: wrap;
			gap: 1rem 0;
			margin-bottom: 2rem;

			> :not([hidden]) ~ :not([hidden]) {
				padding: 0;
			}

			.parameter {
				flex-basis: 100% !important;
				&:first-child,
				&:last-child {
					padding: 0 !important;
				}

				select {
					margin: 0 !important;
				}
			}

			.error-message {
				margin: 1rem auto;
				width: 100%;
			}

			.submit-button-container {
				margin-top: 1rem;
			}
			.error-message-container {
				margin: 2rem;
			}
		}
	}
	.selection {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	.submit-tire-button {
		@media screen and (max-width: 768px) {
			flex-basis: 100% !important;
		}
	}
	img {
		width: 100%;
		margin-top: 1rem;
		&.desktop-diagram {
			display: block;
			@media screen and (max-width: 992px) {
				display: none;
			}

			@media screen and (max-width: 1140px) {
				width: 85%;
			}
		}
		&.mobile-diagram {
			display: none;
			@media screen and (max-width: 992px) {
				display: block;
			}
		}
	}
}
</style>
